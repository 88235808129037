<template>
	<div class="menber-dialog">
		<el-dialog
			title="被推送人列表"
			:visible.sync="visible"
			:close-on-click-modal="false"
			width="60%"
			@close="close"
			center
		>
			<el-form>
				<el-form-item label="用户类型：">
					<el-select v-model="form.type" style="width: 200px">
						<el-option
							v-for="(item, index) in types"
							:key="index"
							:value="item.value"
							:label="item.label"
						></el-option
					></el-select>
				</el-form-item>
				<el-form-item label="所在区域：">
					<el-cascader
						style="width: 200px"
						v-model="form.areaCode"
						:props="areaProps"
						:options="regionOptions"
						:show-all-levels="false"
					></el-cascader>
				</el-form-item>
				<el-form-item label="名称：">
					<el-input style="width: 200px" v-model="form.name" placeholder="请输入关键字"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="query">查询</el-button>
					<el-button type="primary" @click="reset">重置</el-button>
				</el-form-item>
			</el-form>
			<el-transfer
				style="display: flex; justify-content: center; align-items: center"
				v-model="tuisong"
				:data="tableData"
				@change="getObject(option)"
				:props="props"
				:titles="['可选择的用户', '已选择的用户']"
			>
			</el-transfer>
			<div slot="footer" class="dialog-footer" style="text-align: center; margin-top: -20px">
				<el-button @click="close">取消</el-button>
				<el-button type="primary" @click="submit">推送</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import { getUserName } from '@/api/landTrustStatistics'
import { getAreaTree } from '@/api/certificate'
export default {
	data() {
		return {
			form: {
				areaCode: null,
				name: null,
				type: '0',
			},
			regionOptions: [],
			areaProps: {
				label: 'areaName',
				children: 'children',
				value: 'id',
				fullPathName: 'fullPathName',
				checkStrictly: true,
				emitPath: false,
			},
			types: [
				{
					label: '公司',
					value: '0',
				},
				{
					label: '农户',
					value: '1',
				},
			],
			tuisong: [],
			tableData: [],
			props: {
				key: 'saveData',
				label: 'name',
			},
		}
	},
	props: ['visible'],
	watch: {},
	created() {
		this.getUserName()
		this.getRegions()
	},
	methods: {
		async getUserName() {
			let params = { ...this.form }
			console.log(params)
			let res = await getUserName(params)
			let { code, data } = res
			if (code == 0) {
				this.tableData = data.map(x => {
          x.saveData = JSON.stringify(x)
          return x
        })
			}
		},
		query() {
			this.getUserName()
		},
		reset() {
			this.form.areaCode = null
			this.form.type = '0'
			this.form.name = null
			this.getUserName()
		},
		close() {
			this.$emit('close')
		},
		async submit() {
			if (this.tuisong.length == 0) {
				this.$message.warning('请至少选择一个被推送用户')
				return
			}
			this.$emit('save', this.tuisong)
		},
		async getRegions() {
			// 获取行政区树
			let param = {
				level: '5',
				parentCode: null,
			}
			let res = await getAreaTree(param)
			let { code, data, msg } = res
			if (code == 0) {
				this.regionOptions = data
			} else {
				this.$message.error(msg)
			}
		},
	},
}
</script>
<style lang="less" scoped>
/deep/ .el-form {
	display: flex;
	.el-form-item {
		display: flex;
		margin-right: 10px;
		gap: 5px;
	}
}
/deep/ .el-transfer-panel {
	width: 400px;
}
</style>
