<template>
	<div class="wytg">
		<div class="form-list">
			<div class="table-search">
				<h3>资源资产流转推送</h3>
				<!-- 方便找页面 后期可能删除 -->
				<!-- <FormQuery ref="formquery" :data="Query" :key="keyForm" @submit="search" @reset="reset">
				</FormQuery> -->
				<el-form class="" :model="form" label-position="right" label-width="80px" inline ref="form">
					<el-form-item label="项目名称">
						<el-input style="width: 140px" v-model="form.xmmc" placeholder="项目名称"> </el-input>
					</el-form-item>
					<el-form-item label="项目状态">
						<el-select style="width: 140px" v-model="form.xmzt">
							<el-option
								v-for="item in xmzts"
								:key="item.name"
								:label="item.name"
								:value="item.value"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="流转类型">
						<el-select style="width: 140px" v-model="form.lzlx">
							<el-option
								v-for="item in lzlxs"
								:key="item.name"
								:label="item.name"
								:value="item.value"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="产权类别">
						<el-select style="width: 140px" v-model="form.cqlx">
							<el-option
								v-for="item in typeOptions"
								:key="item.label"
								:label="item.label"
								:value="item.value"
							></el-option>
						</el-select>
					</el-form-item>
					<div class="button" style="display: inline-block; margin: 0">
						<el-form-item>
							<el-button class="query-btn" type="primary" @click="query">查询</el-button>
							<el-button class="query-btn" type="primary" @click="clear">清空</el-button>
						</el-form-item>
					</div>
				</el-form>
			</div>
			<div class="table-content">
				<table-page
					:data="tableData"
					:columns="columns"
					:currentPage="query.current"
					:pageSize="query.size"
					:total="total"
					:border="true"
					@sizeChange="handleSizeChange"
					@currentChange="handleCurrentChange"
				>
					<template slot="cqlxbh" slot-scope="scope">
						<span>{{ getCqlx(scope.row.cqlxbh) }}</span>
					</template>
					<template slot="lzlx" slot-scope="scope">
						<span>{{ getLzlx(scope.row.lzlx) }}</span>
					</template>
					<template slot="operation" slot-scope="scope">
						<el-button type="text" size="small" @click="detailClick(scope.$index, scope.row)"
							>查看</el-button
						>
						<el-button type="text" size="small" @click="tuisong(scope.$index, scope.row)"
							>推送</el-button
						>
					</template>
				</table-page>
			</div>
		</div>
		<ThePush
			:visible="visible"
			@close="
				() => {
					visible = false
				}
			"
			@save="savePush"
		></ThePush>
	</div>
</template>
      <script>
import TablePage from '@/components/table/table-page'
import { Column } from './tableColumn'
import { getXzqhInfoToCity, getPushXmxxByPg, saveTs } from '@/api/landTrustStatistics'
import { mapGetters } from 'vuex'
import ThePush from './components/push.vue'
export default {
	components: {
		TablePage,
		ThePush,
	},
	filters: {
		name1Filter(value) {
			return value == 1 ? '全程托管' : '其它'
		},
	},
	data() {
		return {
			keyForm: 1, //下拉框为请求来的数据时监听会用到
			form: {
				xmmc: '',
				xmzt: null,
				lzlx: null,
				cqlx: null,
			},
			total: 0,
			currentPage: 1,
			pageSize: 10,
			tableData: [],
			columns: Column,
			tableData: [],
			typeOptions: [],
			options: [
				{ value: 0, label: '已成交' },
				{ value: 1, label: '未成交' },
			],
			xmzts: [
				{
					name: '全部',
					value: '',
				},
				{
					name: '竞标中',
					value: '1',
				},
				{
					name: '竞标完成',
					value: '2',
				},
				{
					name: '流拍',
					value: '3',
				},
			],
			lzlxs: [
				{
					name: '出租',
					value: 0,
				},
				{
					name: '入股',
					value: 1,
				},
				{
					name: '转让',
					value: 2,
				},
				{
					name: '出让',
					value: 3,
				},
			],
			selectRow: null,
			visible: false,
		}
	},
	created() {
		this.initRegion()
		this.getList()
		this.getTypes()
	},
	computed: mapGetters(['identityObj']),
	methods: {
    // 获取行政区树
		async initRegion() {
			let res = await getXzqhInfoToCity()
			let arr = []
			if (res.status) {
				res.data[0].list.forEach(item => {
					arr.push({
						name: item.xzqmc,
						value: item.xzqbh,
					})
				})
				if (arr.length) {
					let hasRegion = setInterval(() => {
						this.Query[0].dataOptions = arr
						this.keyForm++
						clearInterval(hasRegion)
					}, 100)
				}
			}
		},
    // 切换页码
		handleCurrentChange(page) {
			this.currentPage = page
			this.getList()
		},
    // 切换页容量
		handleSizeChange(size) {
			this.currentPage = 1
			this.pageSize = size
			this.getList()
		},
    // 获取列表数据
		async getList() {
			let params = Object.assign(this.form, {
				current: this.currentPage,
				size: this.pageSize,
			})
			let res = await getPushXmxxByPg(params)
			if (res.status == 200) {
				this.tableData = res.data.records
				this.total = parseInt(res.data.total)
			} else {
				this.$message.error('查询失败')
			}
		},
		async detailClick(index, data) {
			// 查看项目详情
			this.$router.push({ name: 'projectDetail', query: { c: data.xmbh } })
		},
    // 打开推送弹框
		async tuisong(index, row) {
			this.selectRow = row
			this.visible = true
		},
    // 保存推送信息
		async savePush(data) {
			let params = data.map(x => {
				let param = JSON.parse(x)
				return {
					identityId: param.authId,
					identityName: param.name,
					identityType: param.type,
					xmbh: this.selectRow.xmbh,
					userId: param.userId,
				}
			})
			let res = await saveTs(params)
			let { status, message } = res
			if (status == 200) {
				this.$message.success(message)
				this.getList()
				this.visible = false
			} else {
				this.$message.error(message)
			}
		},
    // 获取产权类型中文
		getCqlx(type) {
			let res = ''
			this.typeOptions.map(x => {
				if (x.value == type) {
					res = x.label
				}
			})
			return res
		},
    // 获取流转类型
		getLzlx(type) {
			let res = ''
			switch (type) {
				case 0:
					res = '出租'
					break
				case 1:
					res = '入股'
					break
				case 2:
					res = '转让'
					break
				case 3:
					res = '出让'
					break
				default:
					break
			}
			return res
		},
    // 获取产权类型
		getTypes() {
			let cqList = JSON.parse(localStorage.getItem('types'))
			this.typeOptions = cqList.map(x => {
				return {
					label: x.lx,
					value: x.bh,
				}
			})
		},
    // 条件查询
		query() {
			this.getList()
		},
    // 清空查询条件
		clear() {
			this.form.xmmc = ''
			this.form.xmzt = null
			this.form.lzlx = null
			this.form.cqlx = null
			this.getList()
		},
	},
}
</script>
      <style lang="less" scoped>
.form-list {
	.table-search {
		padding: 0 0 0 30px;
		/deep/.el-button {
			padding: 10px 20px !important;
		}
		.tools {
			margin-bottom: 18px;
			margin-top: 6px;
		}
	}
	.table-content {
		padding: 0 30px;
		margin-top: 6px;
	}
}
</style>
      