export const Column = [
  {
    prop: "xmmc",
    label: "项目名称",
  },
  {
    prop: "cqlxbh",
    label: "产权类型",
    template: true,
  },
  {
    prop: "gpdj",
    label: "挂牌底价",
    width: 110,
  },
  {
    prop: "zcmj",
    label: "面积（亩）",
    width: 150,
  },
  {
    prop: "lznx",
    label: "流转年限",
    width: 90,
  },
  {
    prop: "lzlx",
    label: "流转类型",
    width: 90,
    template: true
  },
  {
    prop: "jjjzsj",
    label: "竞价截止时间",
  },
  {
    prop: 'ytsyh',
    label: "已推送用户",
    width: 160,
  },
  {
    prop: "operation",
    label: "操作",
    width: 120,
    template: true,
  },
]
